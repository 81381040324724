import React, {useEffect} from "react";
import {useSelector} from "react-redux";

import Header from "./Header";
import Modal from "./Modal";
import Table from "./Table";
import Filter from "./Filter"
import {userHasPermission} from "../functions/functions";
import OverduePopup from "./OverduePopup";
import ViewContainer from "./ViewContainer/ViewContainer"
import updateUserData from "../functions/updateUserData";

function ViewPage(props) {

    // Сделано для обновления permissions и group, возможно не везде работает
    // (Если точнее, то не работает там, где не используется этот компонент)

    return (
        <ViewContainer {...props} >
            <Table {...props} />
        </ViewContainer>
    );
}


export default ViewPage