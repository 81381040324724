import React from 'react';

import '../../../styles/CalendarBookingScreenPage.scss'

export default function BookingMainCart({title, responsible, startTime, endTime, empty = false, full= false}) {
    return (
        <div className={`calendar-booking-screen-main-cart ${empty ? 'empty' : ''} ${full ? 'full' : ''}`}>
            <div className={'calendar-booking-screen-main-cart__header'}>
                <span className={'calendar-booking-screen-main-cart__header__label'}>Сейчас</span>
                <div className={'calendar-booking-screen-main-cart__header__text'}>{title || ''}</div>
            </div>
            {responsible && <div className={'calendar-booking-screen-main-cart__responsible'}>
                <span className={'calendar-booking-screen-main-cart__responsible__label'}>Ответственный</span>
                <span className={'calendar-booking-screen-main-cart__responsible__text'}>{responsible}</span>
            </div>}
            <span className={'calendar-booking-screen-main-cart__time'}>{startTime || '...'}-{endTime || '...'}</span>
        </div>
    )
}