import React, {useEffect, useState} from 'react';
import HeaderInside from "../../../components/HeaderInside";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import Alert from "../../../components/Allert";
import FormItem from "../../../components/form/FormItem";
import FormErrorsContext from "../../../functions/formErrorsContext";
import AddedFormItem from "../../../components/form/AddedFormItem";
import {DateTimeField, TimeSwitcher} from "../components";
import {getCalendarBookingList, postCalendarBooking} from "../api";

export default function CalendarBookingCreate(props) {
    const [alert, setAlert] = useState()
    const [fields, setFields] = useState()
    const [formErrors, setFormErrors] = useState()
    const navigate = useNavigate();

    useEffect(() => {
        const getFields = async () => {
            const options = (await getCalendarBookingList({options: true}))?.actions?.POST
            const newFields = {}
            const keys = Object.keys(options || {})
            keys.forEach((key) => {
                newFields[key] = {...options[key], value: ''}
            })
            newFields.participants.value = []
            setFields(newFields)
        }

        getFields()
    }, [])

    const convertChoices = (choices) => {
        return choices.map((choice) => ({...choice, label: choice.display_name}))
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const obj = {...Object.fromEntries(formData.entries()), participants: formData.getAll('participants')}
        const response = await postCalendarBooking(obj)

        if (response.ok) {
            setAlert({type: 'success', title: 'Успешно'})
            navigate('/my-calendar')
        } else {
            setAlert({type: 'error', title: 'Ошибка', text: response?.error})
        }

    }
    return (
        <div className={"page"}>
            <>
                <HeaderInside title={'Создать совещание'}/>
                <hr/>
                <div className="page__content">
                    <form className={"form-container"} onSubmit={onSubmit}>
                        <FormErrorsContext.Provider value={{formErrors, setFormErrors}}>
                            {fields?.title &&
                                <FormItem field={{...fields.title, name: 'title', type: 'text'}} classes="_full"/>}
                            {fields?.booking_time &&
                                <DateTimeField name={'booking_time'}
                                               label={'Дата начала'} required={true}/>}
                            {fields?.booking_duration &&
                                <TimeSwitcher
                                    name={'booking_duration'}
                                    label={'Продолжительность бронирования (мин)'}
                                    required={true}/>}
                            {fields?.meeting_room && <FormItem field={{
                                ...fields.meeting_room,
                                type: 'select',
                                name: 'meeting_room',
                                children: convertChoices(fields.meeting_room.choices),
                                placeholder: '',
                            }} classes="_full"/>}
                            {fields?.responsible && <FormItem field={{
                                ...fields.responsible,
                                type: 'select',
                                name: 'responsible',
                                children: convertChoices(fields.responsible.choices),
                                placeholder: '',
                            }} classes="_full"/>}
                            {fields?.description && <FormItem field={{
                                ...fields.description,
                                type: 'textarea',
                                name: 'description',
                                placeholder: '',
                            }} classes="_full"/>}
                            {fields?.participants &&
                                <AddedFormItem
                                    field={{
                                        ...fields.participants,
                                        choices: convertChoices(fields.participants.choices),
                                        name: 'participants',
                                    }}
                                    classes="_full"
                                    button={'Добавить участника'}
                                />}
                            <div className="form__buttons-panel">
                                <input type="submit" id="save_button" className="d-none" value="Send"/>
                                <label htmlFor="save_button"
                                       className={"save_button_fixed"}>{'Сохранить'}</label>
                            </div>
                        </FormErrorsContext.Provider>
                    </form>
                    {alert && <Alert setAlert={setAlert} alert={alert}/>}
                </div>
            </>
        </div>
    )
}