import React, {useEffect} from "react";
import {useSelector} from "react-redux";

import Header from "../Header";
import Modal from "../Modal";
import Filter from "../Filter"
import {userHasPermission} from "../../functions/functions";
import OverduePopup from "../OverduePopup";
import updateUserData from "../../functions/updateUserData";


function ViewPage(props) {
    const filtersData = useSelector(state => state.filters_data.filtersData)
    const {children} = props

    useEffect(() => {
        updateUserData()
    }, []);


    return (
        <div className="page">
            <Header
                {...props}
                Filter={props.filter ? () => <Modal
                    title='Фильтры'
                    button={{classes: 'filter-btn', title: 'Фильтры', addition: filtersData[props.name]?.length}}>
                    <Filter filter={props.filter} url={props.url} name={props.name}
                            setData={props.setData}
                            filterData={props.filterData}
                            clearFilter={props.clearFilter}
                            selectedFields={filtersData[props.name] ? filtersData[props.name] : []}/>
                </Modal> : false}
                create={props.use_permissions ? userHasPermission('add_' + props.model_name) ? {
                    url: `/${props.name}/create`,
                    title: props.create_title
                } : false : {url: `/${props.name}/create`, title: props.create_title}}/>
            <hr/>
            {children}
            <OverduePopup/>
        </div>
    );
}


export default ViewPage