import React from 'react'
import ViewContainer from '../../components/ViewContainer/ViewContainer'
import EventCalendarTable from './EventCalendarTable'
import AddCalendarBookingButton from "../CalendarBooking/components/AddCalendarBookingButton";

const CalendarView = (props) => {


    return (
        <ViewContainer {...props} addition_components={<AddCalendarBookingButton/>}>
            <EventCalendarTable {...props} />
        </ViewContainer>

    )
}

export default CalendarView