import React, {useEffect, useRef, useState} from "react";
import TimeSelector from "./TimeSelector";
import '../../../styles/CalendarBooking.scss'
import {DayPicker} from "react-day-picker";
import {ru} from "date-fns/locale";


const getFormatedDate = (date) => {
    const iso = new Date(date)
    const DD = String(iso.getDate()).padStart(2, '0')
    const MM = String(iso.getMonth() + 1).padStart(2, '0')
    const YY = iso.getFullYear()
    return `${DD}.${MM}.${YY}`
}

const getIsoString = (date) => {
    const regex = /(\d{0,2})\.?(\d{0,2})\.?(\d{0,4})/
    const matches = date.match(regex)
    const newDate = new Date()
    matches[1] && newDate.setDate(matches[1])
    matches[2] && newDate.setMonth(matches[2] - 1)
    matches[4] && newDate.setFullYear(matches[4])
    return newDate
}

function TimeField({startValue, onChange, placeholder, required = false}) {
    const [timeValue, setTimeValue] = useState(startValue || '12:00');
    const [isShowTimeSelector, setIsShowTimeSelector] = useState(false)

    const timeFieldRef = useRef(null);

    useEffect(() => {
        onChange && onChange(timeValue)
    }, [timeValue]);

    useEffect(() => {
        const onMouseDown = (e) => {
            const target = e.target

            if (target !== timeFieldRef.current && !timeFieldRef.current.contains(target)) {
                setIsShowTimeSelector(false)
            }
        }
        addEventListener('mousedown', onMouseDown)

        return () => {
            removeEventListener('mousedown', onMouseDown)
        }
    }, [])

    return (
        <div ref={timeFieldRef}>
            <input name={'text'}
                   value={timeValue}
                   // onChange={}
                   placeholder={placeholder}
                   required={required}
                   onFocus={() => setIsShowTimeSelector(true)}
                   type={'text'}
                   className={'calendar-booking-time-field'}
            />
            {isShowTimeSelector && <TimeSelector value={timeValue} onChange={setTimeValue} minutesStep={30}/>}
        </div>
    )
}

function DateField({startValue, onChange, placeholder, required = false}) {
    const [value, setValue] = useState(startValue || getFormatedDate(Date.now()))
    const dateFieldRef = useRef(null);
    const [isShowDatePicker, setIsShowDatePicker] = useState(false)


    const selectDate = (data) => {
        setValue(getFormatedDate(data))
    }

    useEffect(() => {
        onChange && onChange(value)
    }, [value])

    useEffect(() => {
        const onMouseDown = (e) => {
            const target = e.target

            if (target !== dateFieldRef.current && !dateFieldRef.current.contains(target)) {
                setIsShowDatePicker(false)
            }
        }
        addEventListener('mousedown', onMouseDown)

        return () => {
            removeEventListener('mousedown', onMouseDown)
        }
    }, [])


    return (
        <div ref={dateFieldRef} className={'calendar-booking-date-field'}>
            <input type={"text"}
                   autoComplete={"off"}
                   className={"date-mask"}
                   placeholder={placeholder}
                   required={required}
                   onFocus={() => setIsShowDatePicker(true)}
                   value={value}/>
            {isShowDatePicker && <div className={'calendar-booking-date-field-date-picker'}>
                <DayPicker
                    mode="single"
                    selected={getIsoString(value)}
                    onSelect={selectDate}
                    showOutsideDays
                    locale={ru}
                />
            </div>}
        </div>
    )
}

export default function DateTimeField({startValue, label, name, required = false}) {
    const [value, setValue] = useState(startValue);

    const getParsingDateTime = (dateTime) => {
        const regex = /(\d{0,2}).?(\d{0,2}).?(\d{0,4}) ?(\d{0,2}):?(\d{0,2})/
        const matches = (dateTime || '').match(regex);
        const DD = matches[1]
        const MM = matches[2]
        const YY = matches[3]
        const hh = matches[4]
        const mm = matches[5]
        return [DD, MM, YY, hh, mm]
    }

    const getTimeValue = (dateTime) => {
        const [date, time] = String(dateTime).split(' ')
        return time || ''
    }

    const setTimeValue = (time) => {
        setValue(prevState => {
            const [DD, MM, YY, _hh, _mm] = getParsingDateTime(prevState)
            return `${DD}.${MM}.${YY} ${time}`
        })
    }

    const getDateValue = (dateTime) => {
        const [DD, MM, YY, _hh, _mm] = getParsingDateTime(dateTime)
        if (DD || MM || YY)
            return `${DD}.${MM}.${YY}`
        return undefined
    }

    const setDateValue = (date) => {
        setValue(prevState => {
            const [_DD, _MM, _YY, hh, mm] = getParsingDateTime(prevState)
            return `${date} ${hh}:${mm}`
        })
    }

    return (
        <div className={"form__item time _full"}>
            <input type='hidden' name={name} value={value}/>
            <div className={'d-flex gap-8'}>
                <DateField startValue={getDateValue(value)} onChange={setDateValue}/>
                <TimeField startValue={getTimeValue(value)} onChange={setTimeValue}/>
            </div>
            <label htmlFor={name}>{label}{required ? <span>*</span> : ''}</label>
        </div>
    )
}