export default function parseDateTime(date) {
    if (date === undefined)
        return {}
    const regex = /(\d{0,2})?.?(\d{0,2})?.?(\d{0,4})? ?(\d{0,2})?:?(\d{0,2})?:?(\d{0,2})?/
    const match = date.match(regex)
    return {
        date: match[1],
        month: match[2],
        year: match[3],
        hours: match[4],
        minutes: match[5],
        seconds: match[6],
    }
}