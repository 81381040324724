import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import HeaderInside from "../../../components/HeaderInside";
import Alert from "../../../components/Allert";
import FormItem from "../../../components/form/FormItem";
import FormErrorsContext from "../../../functions/formErrorsContext";
import AddedFormItem from "../../../components/form/AddedFormItem";
import {DateTimeField, TimeSwitcher} from "../components";
import {getCalendarBookingDetail, patchCalendarBooking} from "../api";

export default function CalendarBookingEdit() {
    const params = useParams()
    const userData = useSelector(state => state.user_data.userData)
    const [alert, setAlert] = useState()
    const [fields, setFields] = useState()
    const [formErrors, setFormErrors] = useState()
    const navigate = useNavigate();

    useEffect(() => {
        const getFields = async () => {
            if (params.id) {
                const data = await getCalendarBookingDetail(params.id)
                const options = (await getCalendarBookingDetail(params.id, true))?.actions?.PUT

                const newFields = {}
                const keys = Object.keys(data || {})
                keys.forEach((key) => {
                    newFields[key] = {...options[key], value: data[key]}
                })
                setFields(newFields)
            }
        }

        getFields()
    }, [params.id])

    const isAuthor = () => {
        return (+fields?.responsible?.value === +userData.user_id)
    }

    const isAdmin = () => {
        const groups = userData.groups ? userData.groups : []
        return groups.indexOf('Admin') !== -1;
    }

    const convertChoices = (choices) => {
        return choices.map((choice) => ({...choice, label: choice.display_name}))
    }

    const convertMembersValue = (value) => {
        return value.map(item => ({value: item}))
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const obj = {...Object.fromEntries(formData.entries()), participants: formData.getAll('participants')}
        const response = await patchCalendarBooking(params.id, obj)

        if (response?.ok) {
            setAlert({type: 'success', title: 'Успешно'})
        } else {
            setAlert({type: 'error', title: 'Ошибка', text: response?.error})
        }

    }
    return (
        <div className={"page"}>
            <>
                <HeaderInside title={'Редактировать совещание'}/>
                <hr/>
                <div className="page__content">
                    <form className={"form-container"} onSubmit={onSubmit}>
                        <FormErrorsContext.Provider value={{formErrors, setFormErrors}}>

                            {fields?.title &&
                                <FormItem field={{...fields.title, name: 'title', type: 'text'}} classes="_full"/>}
                            {fields?.booking_time &&
                                <DateTimeField startValue={fields.booking_time.value} name={'booking_time'}
                                               label={'Дата начала'} required={true}/>}
                            {fields?.booking_duration &&
                                <TimeSwitcher startValue={fields.booking_duration.value}
                                              name={'booking_duration'}
                                              label={'Продолжительность бронирования (мин)'}
                                              required={true}/>}
                            {fields?.meeting_room && <FormItem field={{
                                ...fields.meeting_room,
                                type: 'select',
                                name: 'meeting_room',
                                children: convertChoices(fields.meeting_room.choices),
                                value: fields.meeting_room.value,
                                placeholder: '',
                            }} classes="_full"/>}
                            {fields?.responsible && <FormItem field={{
                                ...fields.responsible,
                                type: 'select',
                                name: 'responsible',
                                children: convertChoices(fields.responsible.choices),
                                value: fields.responsible.value,
                                placeholder: '',
                            }} classes="_full"/>}
                            {fields?.description && <FormItem field={{
                                ...fields.description,
                                type: 'textarea',
                                name: 'description',
                                placeholder: '',
                            }} classes="_full"/>}
                            {fields?.participants &&
                                <AddedFormItem
                                    field={{
                                        ...fields.participants,
                                        value: convertMembersValue(fields.participants.value),
                                        choices: convertChoices(fields.participants.choices),
                                        name: 'participants',
                                    }}
                                    classes="_full"
                                    button={'Добавить участника'}
                                    can_delete={isAuthor() || isAdmin()}
                                    can_add={isAuthor() || isAdmin()}
                                />}
                            {/*{fields?.participants && <FormItem field={{...fields.participants}} classes="_full"/>}*/}
                            {(isAuthor() || isAdmin()) &&  <div className="form__buttons-panel">
                                {/*<a  onClick={e => navigateToView(1)}>ложное сохранение</a>*/}
                                <input type="submit" id="save_button" className="d-none" value="Send"/>
                                <label htmlFor="save_button"
                                       className={"save_button_fixed"}>{'Сохранить'}</label>
                            </div>}
                        </FormErrorsContext.Provider>
                    </form>
                    {alert && <Alert setAlert={setAlert} alert={alert}/>}
                </div>
            </>
        </div>
    )
}