import React from 'react';

import '../../../styles/CalendarBookingScreenPage.scss'

export default function BookingMiniCart({title, responsible, startTime, endTime, empty = false, full=false}) {
    return (
        <div className={`calendar-booking-screen-mini-cart ${empty ? 'empty' : ''} ${full ? 'full' : ''}`}>
            <span className={'calendar-booking-screen-mini-cart__time'}>{startTime || '...'}-{endTime || '...'}</span>
            <div className={'calendar-booking-screen-mini-cart__main'}>
                <div className={'calendar-booking-screen-mini-cart__main__header'}>{title || ''}</div>
                <div className={'calendar-booking-screen-mini-cart__main__responsible'}>
                    {responsible && <span className={'calendar-booking-screen-mini-cart__main__responsible__label'}>Ответственный:</span>}
                    <span className={'calendar-booking-screen-mini-cart__main__responsible__text'}>{responsible}</span>
                </div>
            </div>
        </div>
    )
}