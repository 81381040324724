import React, {useEffect, useRef, useState} from 'react';
import '../../../styles/CalendarBookingScreenPage.scss'
import {getCalendarBookingList} from "../../CalendarBooking/api";
import BookingMainCart from "./BookingMainCart";
import BookingMiniCart from "./BookingMiniCart";
import rightArraySvg from '../../../../public/interface_img/arrow-right.svg'
import getServerTime from "../../CalendarBooking/api/getServerTime";
import {parseDateTime} from "../lib";

const getNowDate = () => {
    const date = new Date()
    return date.toISOString()
}

const getTimeInMinutes = (time) => {
    const [hh, mm] = time.split(':')
    return Number(hh) * 60 + Number(mm)
}

const compDates = (a, b) => {
    const aTime = a?.split(" ")[1]
    const bTime = b?.split(" ")[1]
    return getTimeInMinutes(aTime) - getTimeInMinutes(bTime)
}


const getTimeFromDateTime = (time) => {
    try {
        return time.split(" ")[1] || ''
    } catch (e) {
        return ''
    }
}

const getCartProps = (event) => {
    const convertFio = (fio) => {
        const [l, f, p] = fio.split(" ")
        return `${l} ${f[0] + '.' || ''} ${p[0] + '.' || ''}`
    }
    return {
        responsible: convertFio(event?.responsible),
        startTime: getTimeFromDateTime(event?.booking_time),
        endTime: getTimeFromDateTime(event?.end_booking_time),
        title: event?.title || '',
    }
}

const sortEvents = (events) => {
    return (events || []).sort((a, b) => compDates(a.booking_time, b.booking_time))
}

const filterEvents = (events, curDate) => {
    const hours = parseDateTime(curDate).hours
    const minutes = parseDateTime(curDate).minutes
    const dateNow = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
    return (events || []).filter(item => getTimeInMinutes(item.endTime) > getTimeInMinutes(dateNow))
}

const addEmptyCart = (events) => {
    if (events && events.length > 0) {
        const newEvents = []
        if (getTimeInMinutes(getTimeFromDateTime(events[0].booking_time)) > getTimeInMinutes('09:00')) {
            newEvents.push({
                title: 'Свободно',
                startTime: '09:00',
                endTime: getTimeFromDateTime(events[0].booking_time),
                empty: true,
            })
        }

        newEvents.push(getCartProps(events[0]))

        for (let i = 1; i < events.length; i++) {
            const prevEndDate = events[i - 1].end_booking_time
            const curStartDate = events[i].booking_time
            if (prevEndDate !== curStartDate) {
                newEvents.push({
                    title: 'Свободно',
                    startTime: getTimeFromDateTime(prevEndDate),
                    endTime: getTimeFromDateTime(curStartDate),
                    empty: true,
                })
            }
            newEvents.push(getCartProps(events[i]))
        }

        if (newEvents.length > 0) {
            const lastEvent = newEvents[newEvents.length - 1]
            if (getTimeInMinutes(lastEvent.endTime) < getTimeInMinutes('19:00')) {
                newEvents.push({
                    title: 'Свободно',
                    startTime: lastEvent.endTime,
                    endTime: '19:00',
                    empty: true,
                })
            }
        }

        return newEvents
    }
    return []
}


const addTime = (dateStr, countSeconds) => {
    const [datePart, timePart] = dateStr.split(' ')
    const [day, month, year] = datePart.split('.').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);

    const date = new Date(year, month - 1, day, hours, minutes, seconds);
    date.setSeconds(date.getSeconds() + countSeconds)
    return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
}

export default function CalendarBookingScreenPage({floor} = {floor: 1}) {
    const [events, setEvents] = useState([])
    const [curDateTime, setCurDateTime] = useState(undefined)
    const [isEmptyDay, setIsEmptyDay] = useState(true)

    const curDateTimeRef = useRef(curDateTime);

    useEffect(() => {
        let timeout
        const updateDate = async () => {
            let newTime = curDateTimeRef.current
            let nextUpdate
            if (newTime === undefined) {
                const response = await getServerTime()
                newTime = response?.data?.server_time
                nextUpdate = 60 - Number(getTimeFromDateTime(newTime)?.split(":")[2])
                newTime = addTime(newTime, -Number(getTimeFromDateTime(newTime)?.split(":")[2]))
            } else {
                nextUpdate = 60 - Number(getTimeFromDateTime(newTime)?.split(":")[2])
                newTime = addTime(newTime, nextUpdate)
            }
            setCurDateTime(newTime)
            curDateTimeRef.current = newTime
            timeout = setTimeout(updateDate, nextUpdate * 1000)
        }
        updateDate()
        return () => {
            timeout && clearTimeout(timeout)
        }
    }, []);

    useEffect(() => {
        if (curDateTime !== undefined) {
            const curMinutes = Number(parseDateTime(curDateTime).minutes)
            if (curMinutes === 0 || curMinutes === 30) {
                setEvents(prevState => filterEvents(prevState, curDateTime))
            }
        }
    }, [curDateTime]);

    useEffect(() => {
        const getEvents = async (dateTime) => {
            let date = dateTime
            if (date === undefined) {
                date = (await getServerTime())?.data?.server_time
            }
            if (date !== undefined) {
                const curDate = parseDateTime(date)
                const day = curDate.date
                const month = curDate.month
                const year = curDate.year
                const filterDate = `${String(day).padStart(2, '0')}.${String(month).padStart(2, '0')}.${year}`
                const response = await getCalendarBookingList({
                    meeting_room: `Этаж ${floor}`,
                    token: false,
                    date_before: filterDate,
                    date_after: filterDate
                });
                if (response?.results) {
                    setIsEmptyDay(response.results?.length === 0)
                    const newEvents = filterEvents(addEmptyCart(sortEvents(response.results)), date)
                    setEvents(newEvents)
                }
            }
        }
        let timeout
        const TIMEOUT_DURATION = 1000 * 60 * 0.5
        const updateEvents = () => {
            getEvents(curDateTimeRef.current)
            timeout = setTimeout(updateEvents, TIMEOUT_DURATION)
        }

        updateEvents()
        return () => {
            timeout && clearTimeout(timeout)
        }
    }, []);

    const getContent = () => {
        if (isEmptyDay) {
            return (
                <div className={'calendar-booking-screen-page-main-not-events'}>
                    <div className={'calendar-booking-screen-page-main-not-events-content'}>
                        <span className={'calendar-booking-screen-page-main-not-events'}>Мероприятий</span>
                        <span className={'calendar-booking-screen-page-main-not-events'}>не запланировано</span>
                    </div>
                </div>
            )
        }
        return (
            <div className={'calendar-booking-screen-next-events-block'}>
                <div className={'calendar-booking-screen-next-events-main-cart-block'}>
                    <BookingMainCart {...events[0]} full={events.length === 1}/>
                </div>
                {events.length > 1 && <div className={'calendar-booking-screen-next-events-mini-cart-block'}>
                    <span className={'calendar-booking-screen-next-events-mini-cart-block-label'}>
                        Далее <img src={rightArraySvg}/>
                    </span>
                    <div className={'calendar-booking-screen-next-events-mini-cart-block-list'}>
                        {events.slice(1, events.length).map((item, idx) => <BookingMiniCart {...item}
                                                                                            full={events.length === 2}
                                                                                            key={idx}/>)}

                    </div>
                </div>}
            </div>
        )
    }

    const getDate = () => {
        if (curDateTime === undefined)
            return undefined
        const date = parseDateTime(curDateTime)
        const day = date.date;
        const monthNames = [
            'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
            'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
        ];
        const month = monthNames[date.month - 1];
        const year = date.year;

        return `${day} ${month} ${year}`;
    };

    const getTime = () => {
        if (curDateTime === undefined)
            return undefined
        const date = parseDateTime(curDateTime)
        const hours = date.hours
        const minutes = date.minutes
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

    }

    return (
        <div className={'calendar-booking-screen-page'}>
            <div className={'calendar-booking-screen-page-header'}>
                <div className={'calendar-booking-screen-page-header-label'}>Переговорная {floor} этаж</div>
                <div className={'calendar-booking-screen-page-header-date-time-block'}>
                    <span className={'calendar-booking-screen-page-header-date-time-block-date'}>{getDate()}</span>
                    <span className={'calendar-booking-screen-page-header-date-time-block-time'}>{getTime()}</span>
                </div>
            </div>
            {/*<div className={'calendar-booking-screen-page-main'}>*/}
            {getContent()}
            {/*</div>*/}
        </div>
    )
}